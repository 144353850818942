import HTMLFlipBook from "react-pageflip";
import "./App.css";

function shuffleArray(array) {
  array = array.slice();
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const viecher = shuffleArray([
  "Basti",
  "Eva",
  "Fabi",
  "Gregor",
  "Henny",
  "Jonas",
  "Kimi",
  "Reuben",
]);

const colors = ["#FAC18C", "#FAF998", "#FA7F94", "#66DDFA", "#CCA3FA"];
const getColor = (i) => colors[(i % 2 === 0 ? i : i - 1) % colors.length];

function App() {
  let i = 0;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        fontSize: 30,
        margin: 50,
      }}
    >
      <HTMLFlipBook width={400} height={600} maxShadowOpacity={0.5} showCover>
        <div>
          <div
            style={{
              height: "100%",
              background: getColor(i++),
              padding: 20,
              boxSizing: "border-box",
            }}
          >
            Liebe Lea, <br />
            Alles Schnute wünschen dir jene Schnuten aka deine Super-Fans. Wir
            freuen uns auf deine Hits du Hit.{" "}
            <img src="birthday.gif" style={{ width: "100%" }} />
          </div>
        </div>
        <div>
          <div
            style={{
              height: "100%",
              background: getColor(i++),
            }}
          ></div>
        </div>
        {viecher.map((name) => (
          <div key={name}>
            <div
              style={{
                height: "100%",
                background: getColor(i++),
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  padding: 20,
                }}
              >
                <video
                  src={name.toLowerCase() + ".mp4"}
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ width: "100%" }}
                />
                {name}
              </div>
            </div>
          </div>
        ))}
        <div>
          <div
            style={{
              height: "100%",
              background: getColor(i++),
              textAlign: "center",
              boxSizing: "border-box",
              fontSize: 60,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: 20,
                boxSizing: "border-box",
              }}
            >
              <video
                src="lea.mp4"
                autoPlay
                loop
                playsInline
                muted
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              height: "100%",
              background: getColor(i++),
            }}
          />
        </div>
      </HTMLFlipBook>
    </div>
  );
}

export default App;
